export default{
  banner: {
    titleImg: require('../images/granary/banner_title.png'),
    video:{
      src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/1d26a8eb-17a3bf20b88-0005-502e-0e5-a9c95.mp4',
      poster: require('../images/public/poster/granary.jpg'),
      title: '',
      dec: ''
    } 
  },
  cards:[
    {
      title: '手机App移动管理端  一站式快捷管理',
      content: '黑谷粮仓app跟维修软件打通，在手机上就可以查看门店的一切数据。黑谷粮仓app有12个模块，每个模块都可以设置不同的查看权限。黑谷粮仓app，适用于管理者、销售员、施工人员等。'
    },
    {
      title: '黑谷粮仓',
      content: '管理员在手机上可以查看营业额、会员报表、库存报表、财务报表等;<br/>'+
      '销售人员可以在手机上查看自己的业绩，奖励及时到帐，还可以通过手机查看顾客的消费记录避免重复推销，可以在手机上直接开单、接车，以及跟进等级顾客的潜在项目等;<br/>'+
      '施工人员可以在手机上查看自己的施工车辆详情跟奖励，奖励也可以做到及时到帐。'
    }
  ],
  functions:{
    title: '黑谷粮仓功能特点',
    list: [
      { 
        title: '营业报表',
        content: '系统自动生成门店营业报表，可直观的查看月度、年度、历年的营业额分析情况。系统还针对到店消费的用户，进行台次、消费金额分类分析'
      },
      {
        title: '财务报表',
        content: '该模块由毛利报表、应付款、应收款三大功能组成，可随时查看利润分析、应付款、应收款'
      },
      {
        title: '会员报表',
        content: '查看门店会员情况，包含会员人数、充值金额、可消费金额、次数等会员信息，并可查看当日、月度、年度、历年的会员充值、消费分析'
      },
      {
        title: '库存管理',
        content: '查看库存商品情况，并可快速搜索库存产品品类'
      },
      {
        title: '手机接车',
        content: '通过手机快捷接车，接车员可通过拍摄照片或视频生成检查报表发送给车主。老板或管理者可有效的监管员工是否做好了查车工作，员工也可在后台查看排名'
      },
      {
        title: '客户查询',
        content: '通过输入或扫一扫客户的车牌，可查看该车主的基础资料、车辆资料、会员卡情况。还可通过该模块查看客户的消费记录、回访事项以及直接开单等操作'
      },
      {
        title: '回访管理',
        content: '老板或管理者权限可查看员工的客户回访总览及每个员工的回访情况。普通员工权限可查看个人的客户回访情况以及提醒事项'
      },
      {
        title: '绩效管理',
        content: '查看员工绩效明细、排名、个人工资情况以及每个员工的贡献毛利分析。系统后台提供18个奖励方案，满足不同门店的绩效设置需求'
      }
    ]
  }
}