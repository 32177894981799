
















import { Component, Vue } from 'vue-property-decorator'
import vFreeApply from '@/components/v-free-apply.vue'
import VBanner from '@/components/v-banner.vue'
import VCard from '@/components/v-card.vue'
import granaryData from '../../assets/data/granary'
import vCardList from '@/components/v-card-list.vue' 
import vNavButton from '@/components/v-nav-button.vue'


@Component({ 
  components: { vFreeApply,VBanner, VCard, vCardList, vNavButton }
})
export default class extends Vue {
  private bannerData = granaryData.banner
  private cardsData = granaryData.cards
  private functions = granaryData.functions
}
